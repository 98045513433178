import React from 'react';
import logo from './main-logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          I am a consultant who specializes in iOS apps. I would be glad to help you. Send me an email at contact@michaelsimard.com
        </p>
      </header>
    </div>
  );
}

export default App;
